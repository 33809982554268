import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { ChequesPendentesContext } from 'contexts/ChequesPendentesContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import CustomGrid from 'components/Grid';
import Header from 'components/Header';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';
import Filter from './filter';
import mock from './mock';

const EditModal = ({ item, cb }) => {
  const defaultValues = {
    financeiro_id: item?.id,
    banco_id: item?.banco_id || null,
    agencia: item?.agencia || '',
    conta: item?.conta || '',
    cheque: item?.cheque || '',
    dtemissao: item?.dtemissao || null,
    dtvenc: item?.dtvenc || null,
    nome: item?.nome || '',
    tipo_documento_id: item?.tipo_documento_id || null,
    numdoc: item?.numdoc || '',
    observacao: item?.observacao || '',
  };
  const { control, handleSubmit } = useForm({ defaultValues });
  const { drops } = useContext(DropsContext);
  const { closeModal } = useModal();
  const { putCheque, putLoading } = useContext(ChequesPendentesContext);

  const onSubmit = (data) => putCheque({ data, cb });

  return (
    <>
      <DialogTitle>Editar Cheque</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="banco_id"
              control={control}
              label="Banco"
              options={drops?.EntidadeTipo?.filter(
                (f) => f?.tipo === 'INSTITUICAO FINANCEIRA'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              name="agencia"
              control={control}
              label="Agência"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="conta" control={control} label="Conta" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              name="cheque"
              control={control}
              label="Nº do Cheque"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Emissão"
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="dtvenc"
              control={control}
              label="Data de Vencimento"
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input name="nome" control={control} label="Nome" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Dropdown
              name="tipo_documento_id"
              control={control}
              label="Tipo de Documento"
              options={drops?.TipoDocumento}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Input
              name="numdoc"
              control={control}
              label="Nº do Documento"
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          loading={putLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

const ChequesPendentes = () => {
  const rotina = 'ChequesPendentes';
  const titulo = 'Cheques Pendentes';
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { chequesPendentes, getChequesPendentes, getLoading } = useContext(
    ChequesPendentesContext
  );
  const { control, getValues, reset } = useForm({
    defaultValues: chequesPendentes?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    closeModal();
    getChequesPendentes({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({
          rotina,
          filter: getValues(),
          order: chequesPendentes?.order,
        }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({
          rotina,
          filter: getValues(),
          order: chequesPendentes?.order,
        }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={chequesPendentes?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Baixar Pendências',
      icon: 'price_check',
      action: (cheques) => {
        if (!Boolean(cheques?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        navigate('/app/Pendencias/Baixa/Cheques', { state: { cheques } });
      },
    },
  ];

  const rowOptions = [
    {
      name: 'Editar',
      icon: 'edit',
      action: ({ row }) =>
        openModal(<EditModal item={row} cb={loadGrid} />, 80),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      show: ({ row }) => Boolean(row?.documento_id),
      action: ({ row }) => navigate(`/app/Documento/${row?.documento_id}`),
    },
    {
      name: 'Demonstrativo do Movimento Financeiro',
      icon: 'print',
      menu: true,
      action: ({ row }) =>
        getURLRelatorio({
          data: {
            codigo: 'FINMOV0001',
            mov: row?.financeiro_movimento_id,
          },
          cb: loadGrid,
        }),
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        titulo={titulo}
        colunas={chequesPendentes?.colunas}
      />
      <Card>
        <CustomGrid
          grid={chequesPendentes}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          totalizer="valor"
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={chequesPendentes} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default ChequesPendentes;
